import { datadogRum } from "@datadog/browser-rum";
import axios from "axios";
import { useCallback } from "react";
import { getRewardfulReferralData } from "src/lib/rewardful";
import { proxy, useSnapshot } from "valtio";
import { http } from "../http";
import { hydrateTokenStore, tokenStore } from "./useToken";

export interface SubscriptionPublicMetadata {
  isLaunchPromo2YearsFree?: boolean;
}

export interface GenericSubscription {
  type: "PAYPAL" | "STRIPE";
  class: "BASE_PREMIUM";
  status: "ACTIVE" | "CREATED" | "INACTIVE";
  isActive: boolean;
  endsAt: string | null;
  metadata: SubscriptionPublicMetadata | null;
}

export interface FullPublicUser {
  /**
   * User id, tagged token
   *
   * @example user_039KH58BU376GY4VDFK9JC7Z3
   */
  id: string;
  email: string;
  subscription: GenericSubscription | null;
}

interface UserStore {
  user: FullPublicUser | null;
  retrieved: boolean;
  loginSubmitError: boolean;
}

export const userStore = proxy<UserStore>({
  user: null,
  retrieved: false,
  loginSubmitError: false,
});

export const useUser = () => {
  const refreshUser = useCallback(async () => {
    try {
      const res = await http.request({
        method: "GET",
        url: "/api/user/me",
      });
      if (res?.data?.data?.user) {
        userStore.user = res.data.data.user;
        userStore.retrieved = true;
      } else {
        console.error("User not present on /api/user/me response object", res?.data);
      }
    } catch (e) {
      userStore.retrieved = true;
      userStore.user = null;
    }
  }, []);

  const loginUserUsingEmailToken = useCallback(
    async (emailToken: string) => {
      if (!emailToken) {
        return;
      }

      if (!tokenStore.securityToken) {
        hydrateTokenStore();
        if (!tokenStore.securityToken) {
          console.warn("No security token even after hydration", tokenStore.securityToken);
        }
      }
      try {
        const loginSession = await http.request({
          method: "post",
          url: "/api/user/login/submit",
          data: {
            token: emailToken,
            securityToken: tokenStore.securityToken,
            referralData: getRewardfulReferralData(),
          },
        });
        if (loginSession?.data?.data?.session) {
          const loginSessionReponse = loginSession.data;
          const sessionToken = loginSessionReponse.data.session;
          tokenStore.token = sessionToken;
          refreshUser();
          return;
        } else {
          console.error("No session in the response", { loginSession });
        }
      } catch (e) {
        userStore.loginSubmitError = true;
        if (axios.isAxiosError(e)) {
          console.error("Got an HTTP error while submitting login", e, {
            json: e?.toJSON(),
            request: e?.request,
            data: e?.response?.data,
            token: emailToken,
            securityToken: tokenStore.securityToken,
          });
          datadogRum.addError(e, {
            token: emailToken,
            securityToken: tokenStore.securityToken,
            json: e?.toJSON(),
            request: e?.request,
            data: e?.response?.data,
          });
        } else {
          console.error("Unknown error fom submitting login", e, {
            token: emailToken,
            securityToken: tokenStore.securityToken,
          });
          datadogRum.addError(e, {
            token: emailToken,
            securityToken: tokenStore.securityToken,
          });
        }
      }
    },
    [refreshUser],
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const userState: UserStore = (typeof window !== "undefined" ? useSnapshot(userStore) : {}) as any;

  return {
    userState,
    refreshUser,
    loginUserUsingEmailToken,
  };
};
