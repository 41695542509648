export const DISCORD_LINK = "https://redact.dev/discord";

export const pricingOptions = {
  month: {
    key: "month",
    priceVariant: "1_MONTH_DEFAULT",
    price: "34.99",
    monthly: "34.99",
  },
  year: {
    key: "year",
    priceVariant: "1_YEAR_DEFAULT",
    price: "95.88",
    monthly: "7.99",
    savingsPercent: "77",
  },
  tri: {
    key: "tri",
    priceVariant: "3_MONTH_DEFAULT",
    price: "53.97",
    monthly: "17.99",
    savingsPercent: "44",
  },
  life: {
    key: "tri",
    priceVariant: "LIFETIME_DEFAULT",
    price: "244.99",
  },
};

export const professionalPricingOptions = {
  month: {
    key: "month",
    priceVariant: "1_MONTH_PRO",
    price: "74.99",
    monthly: "74.99",
  },
  year: {
    key: "year",
    priceVariant: "1_YEAR_PRO",
    price: "179.99",
    monthly: "14.99",
  },
  life: {
    key: "life",
    priceVariant: "LIFETIME_PRO",
    price: "384.99",
  },
};


export const genericDownloadLink =
  typeof window !== "undefined"
    ? navigator?.platform?.indexOf("Mac") > -1
      ? "https://download.redact.dev/macos"
      : navigator?.platform?.indexOf("Win") > -1
      ? "https://download.redact.dev/windows"
      : "https://download.redact.dev/linux"
    : false;
export const downloadsDisabled = false;
export const downloadButtonText = "Download Redact";

export const downloadLinksArray = [
  { icon: "apple", link: "https://download.redact.dev/macos", actionName: "MacOS Icon" },
  { icon: "windows", link: "https://download.redact.dev/windows", actionName: "Windows Icon" },
  { icon: "linux", link: "https://download.redact.dev/linux", actionName: "Linux Icon" },
  {
    icon: "playstore",
    external: true,
    link: "https://play.google.com/store/apps/details?id=dev.redact.app",
    actionName: "Android Playstore Icon",
  },
  {
    icon: "appstore",
    external: true,
    link: "https://apps.apple.com/us/app/redact-privacy/id6449900531",
    actionName: "iOS Appstore Icon",
  },
];
export const navLinksArray = [
  { label: "Services", link: "/services" },
  { label: "Features", link: "/features" },
  { label: "Pricing", link: "/pricing" },
  { label: "Business", link: "/business" },
  { label: "Testimonials", link: "/testimonials" },
  { label: "Contact", link: "/contact" },
  { label: "Log in", link: "/login" },
];
export const footerSocialMediaArray = [
  { icon: "discord", link: DISCORD_LINK },
  { icon: "instagram", link: "https://instagram.com/redactdev" },
  { icon: "twitter", link: "https://twitter.com/redactdev" },
];

export const servicesArray = [
  {
    color: "#6679d3",
    bgcolor: "#3c4478",
    title: "Discord",
    icon: "discord",
    description: `Cleanse your cringe Discord history by either nuking it all, or choosing a specific server or direct message, then specifying keywords and date ranges.`,
    removable: "Messages in Servers, Direct Messages, Images, and Videos",
    types: ["social", "messaging"],
  },
  {
    color: "#40aadb",
    bgcolor: "#1871bf",
    title: "Twitter",
    icon: "twitter",
    description:
      "Delete old tweets, pictures, and videos for rebranding, spring cleaning, or just plain peace of mind.",
    removable: "Tweets, Retweets, Images, Videos, and Direct Messages",
    types: ["social"],
  },
  {
    color: "#ff6538",
    bgcolor: "#ff4301",
    title: "Reddit",
    icon: "reddit",
    description:
      "Remove old Reddit posts and comments to get rid of any old skeletons in your post history losing you karma.",
    removable: "Reddit Posts, Direct Messages, Reddit Comments",
    types: ["social"],
  },
  {
    color: "#2d86e5",
    bgcolor: "#1158c7",
    title: "Facebook",
    icon: "facebook",
    description: `Delete anything that you posted either on your own profile or somebody else's`,
    removable: "Posts and Comments",
    types: ["social"],
  },
  {
    color: "#e0e0e0",
    bgcolor: "#66c0f4",
    title: "Steam",
    icon: "steam",
    description: `Delete your steam comments.`,
    removable: "Comments",
    types: ["social"],
  },  {
    title: "Instagram",
    color: "#b22490",
    bgcolor: "linear-gradient(45deg, #a3681d 0%,#ab6b3e 25%,#a53f52 50%,#9d8a9e 75%,#a34f9a 100%)",
    icon: "instagram",
    description: `Delete any images you've posted on Instagram.`,
    removable: "Images", //can we delete likes?
    types: ["social"],
  },

  {
    color: "#b58cff",
    bgcolor: "#5a13c0",
    title: "Twitch",
    icon: "twitch",
    description: `Rapidly remove any VODs or clips made of your stream that may be breaking TOS, or remove clips you've made of other people's streams.`,
    removable: "VODs and Clips",
    types: ["social"],
  },
  {
    color: "#682168",
    bgcolor: "linear-gradient(-120deg, #3c8473, #7a9cad, #9f7b00, #a94a92)",
    title: "Slack",
    icon: "slack",
    description: `Rapidly comb through your history and remove your Slack messages.`,
    removable: "Messages",
    types: ["work"],
  },
  {
    color: "#b80214",
    bgcolor: "#8c000e",
    title: "Youtube",
    icon: "youtube",
    description: `Delete your youtube videos and comments`,
    removable: "Videos, comments",
    types: ["social"],
  },
  {
    color: "#42bf95",
    bgcolor: "#507500",
    title: "Imgur",
    icon: "imgur",
    description: `Delete those old images & comments from Imgur still hanging around.`,
    removable: "Images and Comments",
    types: ["social"],
  },
  {
    color: "#18C78C",
    bgcolor: "#007a1c",
    title: "Deviantart",
    icon: "deviantart",
    description: `Remove comments, favorites, or even your own old art posts.`,
    removable: `Comments, Favorites, and Posts`,
    types: ["social"],
  },
  {
    color: "#bc1414",
    bgcolor: "#780000",
    title: "Pinterest",
    icon: "pinterest",
    description: `Mass unpin images and delete boards.`,
    removable: "Pins and Boards",
    types: ["social"],
  },
  {
    color: "#5b7cff",
    bgcolor: "#003d84",
    title: "LinkedIn",
    icon: "linkedin",
    description: `Get a fresh start in your professional life by deleting any conversations or posts you've ever made.`,
    removable: "Conversations and Posts",
    types: ["work"],
  },
  {
    color: "#cccccc",
    bgcolor: "#002340",
    title: "Tumblr",
    icon: "tumblr",
    description: `Remove unwanted content from your Tumblr page, either already published or just queued.`,
    removable: "Posts and Queued Posts",
    types: ["social"],
  },
  {
    color: "#3a52e2",
    bgcolor: "#002a8a",
    title: "Microsoft Teams",
    icon: "ms-teams",
    description: `Delete those random messages from all around the assorted rooms you've been in.`,
    removable: "Messages",
    types: ["social", "messaging", "work"],
  },
  {
    color: "#549fdf",
    bgcolor: "#006da3",
    title: "Skype",
    icon: "skype",
    description: `Cleanse ancient Skype messages and chat logs.`,
    removable: "Messages, Group Messages, and Chat Logs",
    types: ["work"],
  },

  {
    color: "#5e7df5",
    bgcolor: "#0073c7",
    title: "Telegram",
    icon: "telegram",
    description: `Delete your messages from Telegram.`,
    removable: "Messages",
    types: ["social", "messaging"],
  },
  /*
  {
    title: "Messenger",
    icon: "messenger",
    description: `Delete your messages from Messenger.`,
    removable: "Messages",
    disabled: true,
    types: ["social", "messaging", "work"],
  },
  */
  {
    color: "#ce406e",
    bgcolor: "#ffd8db",
    title: "Tinder",
    icon: "tinder",
    description: `Clean your undoubtedly prolific match history.`,
    removable: "Matches",
    types: ["social", "messaging"],
  },
  {
    color: "#146ec1",
    bgcolor: "#205296",
    title: "Stack Exchange",
    icon: "stack-exchange",
    description: `Delete your old amateurish suggestions or stupid questions from Stack Exchange, be they in the form of posts or comments.`,
    removable: "Posts and comments",
    types: ["work"],
  },
  {
    title: "E-mail",
    icon: "imap",
    description: `Purge your E-mail inbox.`,
    removable: "Emails",
    types: ["work"],
  },
  {
    color: "#e14a70",
    bgcolor: "linear-gradient(-120deg, #9e4d5c, var(--raised-background-color), #4c919f)",
    title: "TikTok",
    icon: "tiktok",
    description: `Banish your cringe TikTok videos to the shadow realm.`,
    removable: "Videos",
    types: ["social"],
    disabled: true,
  },
  {
    color: "#007097",
    bgcolor: "#004a6f",
    title: "Blogger",
    icon: "blogger",
    description: "Delete your blog posts and comments",
    removable: "Blogs, Comments",
    types: ["work"],
  },
  {
    color: "#0684ad",
    bgcolor: "#004a6f",
    title: "Wordpress",
    icon: "wordpress",
    description: "Delete your blog posts and comments",
    removable: "Blogs, Comments",
    types: ["work"],
  },
  {
    color: "#1a55ba",
    bgcolor: "#002175",
    title: "MyAnimeList",
    icon: "my-anime-list",
    description: "Delete your comments",
    removable: "comments",
    types: ["social"],
  },
  {
    color: "#54bae2",
    bgcolor: "linear-gradient(-120deg, #9f6200, #3c845f, #4d96a8)",
    // color: "linear-gradient(225deg, #ff8000 0%, #00e054 25%, #00e054 50%, #40bcf4 100%)",
    title: "Letterboxd",
    icon: "letterboxd",
    description: "Delete your things",
    removable: "things",
    types: ["social"],
  },
  {
    color: "#53b8ff",
    bgcolor: "#0067cc",
    title: "Disqus",
    icon: "disqus",
    description: "Delete your things",
    removable: "things",
    types: ["social"],
  },
  {
    color: "#d43b34",
    bgcolor: "#790000",
    title: "Quora",
    icon: "quora",
    description: "Delete your questions and answers",
    removable: "questions, answers",
    types: ["work"],
  },
  {
    color: "#778ad2",
    bgcolor: "#e9e9e9",
    title: "Github",
    icon: "github",
    description: "Delete your follows, issues, comments, gists",
    removable: "follows, issues, comments, gists",
    types: ["work"],
  },
  {
    color: "#19d666",
    bgcolor: "#007c33",
    title: "Spotify",
    icon: "spotify",
    description: "Delete your songs, playlists, follows, likes, albums, podcasts, and artists",
    removable: "songs, playlists, follows, likes, albums, podcasts, artists",
    types: ["social"],
  },
  /*
  {
    color: "linear-gradient(-120deg, #00B2FF, #006AFF)",
    bgcolor: "linear-gradient(-120deg, #00B2FF, #006AFF)",
    title: "VK Messenger",
    icon: "vk-messenger",
    description: "Delete your messages",
    removable: "messages",
    disabled: true,
    types: ["messaging", "social"],
  },
  */
  {
    color: "#42bf95",
    bgcolor: "#a88f00",
    title: "IMDB",
    icon: "imdb",
    description: "Delete your ratings and reviews",
    removable: "ratings, reviews",
    types: ["social"],
  },
  {
    color: "#38ad86",
    bgcolor: "#38ad86",
    title: "Gyazo",
    icon: "gyazo",
    description: "Delete your images",
    removable: "images",
    types: ["social"],
  },
  {
    color: "#343434",
    bgcolor: "#006db5",
    title: "Vimeo",
    icon: "vimeo",
    description: "Delete your videos",
    removable: "videos",
    types: ["work"],
  },
  {
    color: "#FFCE5A",
    bgcolor: "#a68c00",
    title: "Bumble",
    icon: "bumble",
    description: "Delete your matches",
    removable: "matches",
    types: ["social"],
  },
  {
    color: "#f23c95",
    bgcolor: "linear-gradient(-120deg, #0079d6, #9e4d5a)",
    title: "Flickr",
    icon: "flickr",
    description: "Delete your photos, albums, favorites, and galleries",
    removable: "photos, albums, favorites, galleries",
    types: ["work"],
  },
  {
    color: "#5b7cff",
    title: "Medium",
    icon: "medium",
    description: "Delete your articles and comments",
    removable: "comments, articles",
    types: ["work"],
  },
  {
    color: "#f0462d",
    bgcolor: "#9e2f2c",
    title: "Yelp",
    icon: "yelp",
    description: "Delete your reviews",
    removable: "reviews",
    types: ["work"],
  },
  {
    color: "#383838",
    bgcolor: "#007cd6",
    title: "Pixiv",
    icon: "pixiv",
    description: "Delete your images and bookmarks",
    removable: "images, bookmarks",
    types: ["social"],
  },
  {
    color: "#0076FF",
    bgcolor: "#007cd6",
    title: "Bluesky",
    icon: "bluesky",
    description: "Delete your posts, reposts, replies and likes",
    removable: "posts, reposts, likes, replies",
    types: ["social"],
  },
   {
    color: "#634FF5",
    bgcolor: "#634FF5",
    title: "Mastodon",
    icon: "mastodon",
    description: "Delete your posts, reposts, favorites and replies",
    removable: "posts, reposts, favorites, replies",
    types: ["social"],
  },
];

export const teamArray = [
  {
    image: "/images/redacto.png",
    name: "Redacto",
    position: "Purr-oject Manager",
  },
  {
    image: "/images/trent.jpg",
    name: "Trent",
    position: "Fullstack Developer",
  },
  {
    image: "/images/se.jpg",
    name: "Kevin",
    position: "Chief Frontend Developer",
  },
  { image: "/images/jordan.jpg", name: "Jordan", position: "Devops" },
  {
    image: "/images/patrick.jpg",
    name: "Patrick",
    position: "Fullstack Developer",
  },
  { image: "/images/taybor.png", name: "Taybor", position: "Business Development" },
  { image: "/images/nick.jpg", name: "Nick", position: "ML/AI" },
  {
    image: "/images/raymar.png",
    name: "Raymar",
    position: "Designer",
  },
  {
    image: "/images/john.jpg",
    name: "John",
    position: "Senior Developer",
  },
  {
    image: "/images/dan.jpg",
    name: "Daniel",
    position: "Product Manager",
  },
  {
    image: "/images/michele.jpg",
    name: "Michele",
    position: "President of Product Management",
  },
  {
    image: "/images/glock.png",
    name: "Collin",
    position: "Fullstack Developer",
  },
  {
    image: "/images/kira.png",
    name: "Kira",
    position: "Fullstack Developer",
  },
  {
    image: "/images/sam.png",
    name: "Sam",
    position: "Fullstack Developer",
  },
  {
    image: "/images/ForceTower.png",
    name: "João",
    position: "Mobile Developer",
  },
];

export const deletableTypes = {
  twitter: "tweets",
  facebook: "posts",
  discord: "messages",
  reddit: "comments",
  pinterest: "pins",
  tumblr: "posts",
  imgur: "comments",
  twitch: "clips",
  deviantart: "comments",
  instagram: "posts",
  tinder: "matches",
  "stack-exchange": "comments",
  imap: "emails",
  tiktok: "videos",
  steam: "comments",
  wordpress: "comments",
  "my-anime-list": "comments",
  letterboxd: "things",
  disqus: "comments",
  quora: "questions and answers",
  github: "comments",
  spotify: "content",
  vk: "messages",
  imdb: "reviews",
  gyazo: "images",
  vimeo: "videos",
  bumble: "matches",
  flickr: "photos",
  bluesky: "posts",
  mastodon: "posts",
};
